<template>
	<Error :errors="errors"/>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
			<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
				<div class="text-700 text-center">
					<div class="text-blue-600 font-bold mb-3">&nbsp;SELAMAT DATANG DI</div>
					<div class="text-900 font-bold text-5xl mb-3">Web Report - {{appsName}}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="grid">
		<div class="col-12 md:col-12 lg:col-12">
			<UserAktif2/>
        </div>
	</div>
	<div class="grid">
		<div class="col-12 md:col-12 lg:col-12">
			<Outlet/>
        </div>
		<div class="col-12 md:col-12 lg:col-12">
			<Konsumen/>
        </div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import UserAktif2 from '../components/dashboard/UserAktif2.vue';
import Outlet from '../components/dashboard/Outlet.vue';
import Konsumen from '../components/dashboard/Konsumen.vue';
import Error from '../components/Error.vue';

export default {
	components: {
		'UserAktif2': UserAktif2,
		'Outlet': Outlet,
		'Konsumen': Konsumen,
		'Error': Error,
	},
    computed:{
        ...mapGetters(['errors']),
    },
	data() {
		return {		
			appsName: process.env.VUE_APP_APPS_NAME,
		}
	},
}
</script>